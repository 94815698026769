<template>
  <div class="capital-account-init-detail">
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="AmountInfo" :tab="$t(`账户余额信息`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct" v-show="!loading">
          <OperateBtn
            v-if="formData.status === 'WAIT_CHECK'"
            api="checkCapitalInit"
            :text="$t('审核')"
            :params="{
              idList: [id],
            }"
            @success="init"
          />
          <!-- <OperateBtn
            class="ml10"
            v-if="isDetail && ['DRAFT', 'WAIT_CHECK', 'WAIT_PAY'].includes(formData.status)"
            api="operateAssetImpairment"
            type="danger"
            :text="$t('作废')"
            :params="{
              action: 'DELETE',
              idList: [id],
            }"
            @success="init"
          /> -->
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox id="BaseInfo" :title="$t('基本信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据编号`)">
                    <a-input disabled v-model="formData.orderSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算组织`)" required>
                    <CommonSelect
                      :isBu="true"
                      :list="$store.state.settlementBuList"
                      :disabled="isDisabled"
                      :code.sync="formData.businessUnitCode"
                      :name.sync="formData.businessUnitName"
                      @change="changeBU"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`启用期间`)" required>
                    <CommonQuerySelect
                      api="queryAccountingCalendarDetailPage"
                      valueName="accounting_period_code"
                      labelName="accounting_period_name"
                      :code.sync="formData.accountingPeriod"
                      :placeholer="$t(`启用期间`)"
                      :disabled="isDetail"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`本位币`)" required>
                    <SelectCurrency :code="formData.functionalCurrency" :disabled="true" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`状态`)">
                    <a-input
                      :value="$store.getters.initCapitalBalanceStatusMapping[formData.status]"
                      :disabled="true"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>
            <ModalBox id="AmountInfo" :title="$t('账户余额信息')">
              <template v-slot:header>
                <div class="option-btns pr20" v-if="!isDisabled">
                  <span class="text-link fz16" @click="showAccountModal">
                    {{ $t('增行') }}
                  </span>
                  <span class="text-link fz16 ml10" @click="deleteAccount">
                    {{ $t('删行') }}
                  </span>
                </div>
              </template>

              <CommonTable
                :columns.sync="columns"
                :dataSource="formData.detailList"
                :selectedRowKeys.sync="selectedRowKeys"
                :showSelect="!isDisabled"
                :scroll="{ x: 1200 }"
                :showPagination="false"
              >
                <template v-slot:balance="{ record }">
                  <PriceInput
                    :value.sync="record.balance"
                    :disabled="isDisabled"
                    :currency="record.currency"
                  />
                </template>
                <template v-slot:settlementMethod="{ record }">
                  <CommonQuerySelect
                    :init="isDetail"
                    api="getSettlementMethodList"
                    :placeholder="$t(`结算方式`)"
                    :code.sync="record.settlementMethod"
                    :disabled="isDisabled"
                  />
                </template>
              </CommonTable>
            </ModalBox>
          </a-form-model>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>

    <SelectCapitalAccountModal
      ref="CapitalAccountModal"
      @ok="handleAssetCardOk"
    ></SelectCapitalAccountModal>
  </div>
</template>

<script>
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import BottomBtns from '@component/bottomBtns'
import OperationLog from '@component/operationLog'
import SelectCurrency from '@component/selectCurrency'
import SelectCapitalAccountModal from '@component/selectCapitalAccountModal'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { convertKeysToCamelCase, goBack } from '@/common'
export default {
  name: 'CapitalAccountInitDetail',
  components: { BottomBtns, OperationLog, SelectCurrency, SelectCapitalAccountModal },
  data() {
    return {
      docType: 'CapitalAccountInit',
      id: undefined,
      sn: undefined,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {
        status: 'DRAFT',
        detailList: [],
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
      selectedRowKeys: [],

      columns: [],
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 280 + 'px'
    },
  },
  methods: {
    init() {
      this.isDetail = false
      this.isEdit = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
        this.formData.accountingPeriod = moment().format('YYYY-MM')
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      }
      this.getDetailInfo()
      this.columns = [
        {
          title: this.$t('银行账号'),
          dataIndex: 'code',
          width: 200,
          customRender: (text, record) => text || record.capitalAccountCode,
        },
        {
          title: this.$t('账户名称'),
          dataIndex: 'name',
          customRender: (text, record) => text || record.capitalAccountName,
        },
        {
          title: this.$t('组织'),
          dataIndex: 'businessUnitName',
          customRender: (text) => text || this.formData.businessUnitName,
        },
        {
          title: this.$t('结算方式'),
          dataIndex: 'settlementMethod',
          scopedSlots: { customRender: 'settlementMethod' },
          width: 200,
        },
        {
          title: this.$t('币别'),
          dataIndex: 'currency',
          customRender: (text) => this.$store.getters.currencyListMapping[text],
        },
        {
          title: this.$t('期初余额'),
          dataIndex: 'balance',
          scopedSlots: { customRender: 'balance' },
          width: 200,
        },
      ]
    },
    changeBU(value) {
      this.formData.functionalCurrency = value.currency
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    async getDetailInfo() {
      let capitalAccount = localStorage.getItem('capitalAccount')
      if (this.id) {
        this.loading = true
        await http({
          url: api.getCapitalInitDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            this.formData = {
              ...this.formData,
              ...result,
            }
            if (result.status === 'DRAFT') {
              this.isEdit = true
            }
          },
        })
        this.loading = false
      } else if (capitalAccount) {
        capitalAccount = JSON.parse(capitalAccount)
        this.handleAssetCardOk([capitalAccount])
        localStorage.removeItem('capitalAccount')
      }
    },
    backForm(back = 0) {
      goBack('capitalAccountInitList', back)
    },
    onSubmit: debounce(async function (status) {
      const data = deepClone(this.formData)
      data.status = status
      if (this.id) {
        data.id = this.id
      }
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.detailList = data.detailList.map((item) => ({
        balance: item.balance,
        capitalAccountCode: item.code,
        capitalAccountId: item.id,
        capitalAccountName: item.name,
        currency: item.currency,
        initCapitalBalanceId: item.initCapitalBalanceId,
        settlementMethod: item.settlementMethod,
      }))
      await http({
        url: api.createOrUpdateCapitalInit,
        data,
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    showAccountModal() {
      this.$refs.CapitalAccountModal.open()
    },
    deleteAccount() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.formData.detailList = this.formData.detailList.filter(
        (item, index) => !this.selectedRowKeys.includes(index)
      )
      this.selectedRowKeys = []
    },
    handleAssetCardOk(list) {
      this.formData.detailList = [...this.formData.detailList, ...list]
      this.$refs.CapitalAccountModal.close()
    },
  },
}
</script>
